<script>
import Layout from "@/layouts/main.vue"
import API from "@/plugins/axios-method";
import PageHeader from "@/components/page-header.vue";
import NotFound from "@/components/not-found.vue";
import DataLoader from "@/components/data-loader.vue";
import AccountOwnerInformation from "@/components/dashboard/account/account-owner-information.vue";
import AccountBasiqueInformations from "@/components/dashboard/account/account-basique-informations.vue";
import AccountSubscription from "@/components/dashboard/account/account-subscription.vue";
import AccountTransactions from "@/components/dashboard/account/account-transactions.vue";
import AccountFundingRequest from "@/components/dashboard/account/account-funding-request.vue";
import AccountFunding from "@/components/dashboard/account/account-funding.vue";
import RegularisationTransactionFormModal
  from "../../../components/dashboard/transaction/regularisation-transaction-form-modal.vue";
import {
  DEPOSITCONTRIBUTIONTRANSACTION,
  REGULARISATIONCONTRIBUTIONTRANSACTION,
  REGULARISATIONSUBSCRIPTION
} from "../../../helpers/constant/modal-type";
import TransactionConfirmationFormModal
  from "../../../components/dashboard/transaction/transaction-confirmation-form-modal.vue";
import RegularisationSubscriptionFormModal
  from "../../../components/dashboard/subscription/regularisation-subscription-form-modal.vue";
import DepositContributionProgramTransactionFormModal
  from "../../../components/dashboard/transaction/deposit-contribution-program-transaction-form-modal.vue";
export default {
  name: "single-account",
  components: {
    DepositContributionProgramTransactionFormModal,
    RegularisationSubscriptionFormModal,
    TransactionConfirmationFormModal,
    RegularisationTransactionFormModal,
    AccountFunding,
    AccountFundingRequest,
    AccountTransactions,
    AccountSubscription,
    AccountBasiqueInformations, AccountOwnerInformation, DataLoader, NotFound, PageHeader, Layout},
  data(){
    return {
      account: null,
      showDataLoader: true,
      notFound: false,
      onlinePaymentReq: null,
      showRegularisationTransactionFormModal: false,
      showRegularisationSubscriptionModal: false,
      showTransactionConfirmationFormModal: false,
      showDepositContributionTransactionFormModal: false
    }
  },
  mounted() {
    this.getAccount()
  },
  methods: {
    getAccount(){
      API.get('admin/accounts/' + this.$route.params.id)
          .then(response => {
            this.account = response.data
          })
          .catch(e => {
            if(e.response.status === 404){
              this.notFound = true
            }
          })
          .finally(() => {
            this.showDataLoader = false
          })
    },
    openModal(type){
      switch (type) {
        case REGULARISATIONCONTRIBUTIONTRANSACTION:
          this.showRegularisationTransactionFormModal = true
          break
        case REGULARISATIONSUBSCRIPTION:
          this.showRegularisationSubscriptionModal = true
          break
        case DEPOSITCONTRIBUTIONTRANSACTION:
          this.showDepositContributionTransactionFormModal = true
          break
      }
    },
    onModalClose(type){
      switch (type) {
        case REGULARISATIONCONTRIBUTIONTRANSACTION:
          this.showRegularisationTransactionFormModal = false
          break
        case REGULARISATIONSUBSCRIPTION:
          this.showRegularisationSubscriptionModal = false
          break
        case DEPOSITCONTRIBUTIONTRANSACTION:
          this.showDepositContributionTransactionFormModal = false
          break
      }
    },
    switchModal(payload){
      const {type, data} = payload
      this.onlinePaymentReq = data
      console.log(payload, this.showTransactionConfirmationFormModal, this.showRegularisationSubscriptionModal)
      switch (type) {
        case REGULARISATIONCONTRIBUTIONTRANSACTION:
          this.showRegularisationTransactionFormModal = false
          this.showTransactionConfirmationFormModal = true
          break
        case REGULARISATIONSUBSCRIPTION:
          this.showRegularisationSubscriptionModal = false
          this.showTransactionConfirmationFormModal = true
          break
        default:
          console.log('default')
      }
    }
  },
  computed: {
    canShowData(){
      return !this.showDataLoader && this.account
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Compte numéro ' +this.$route.params.id"
        :show-search="false"
        :show-add-button="false"
        show-deposit-contribution-button
        showRegularisationButton
        @openModal="openModal"
    />
    <deposit-contribution-program-transaction-form-modal
        :account="account"
        v-model="showDepositContributionTransactionFormModal"
        @onModalClose="onModalClose"
    />
    <regularisation-transaction-form-modal
        :account="account"
        v-model="showRegularisationTransactionFormModal"
        @onModalClose="onModalClose"
        @switchModal="switchModal"
    />
    <regularisation-subscription-form-modal
        :account="account"
        v-model="showRegularisationSubscriptionModal"
        @onModalClose="onModalClose"
        @switchSubscriptionModal="switchModal"
    />
    <transaction-confirmation-form-modal v-model="showTransactionConfirmationFormModal" :onlinePaymentReq="onlinePaymentReq" />
    <DataLoader v-if="showDataLoader"/>
    <NotFound v-if="!showDataLoader && notFound"/>
    <section v-if="canShowData">
      <b-row>
        <b-col md="6">
          <account-owner-information :account="account" />
        </b-col>
        <b-col md="6">
          <account-basique-informations :account="account" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <account-subscription :account="account" />
        </b-col>
        <b-col md="12">
          <account-transactions :transactions="account.successfullTransactions"/>
        </b-col>
        <b-col md="12">
          <account-funding-request :funding-requests="account.fundingRequests"/>
        </b-col>
        <b-col md="12">
          <account-funding :funding="account.fundings"/>
        </b-col>
      </b-row>
    </section>
  </Layout>
</template>

<style scoped>

</style>