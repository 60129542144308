<script>
import {ADMIN_, COMMERCIAL_, COORDINATOR_, SUPERVISOR_} from "@/helpers/constant/roles";
import {inject} from "vue";
import {getUrlPrefixToShow} from "@/helpers/functions";

export default {
  name: "account-owner-information",
  props: {
    account: {
      type: Object,
      default: null,
      required: true
    }
  },
  setup() {
    const globals = inject("globals");

    return {globals}
  },
  methods: {
    getUrlPrefixToShow
  },
  computed: {
    getDetailPrefixUrl() {
      let role = ""
      if (this.globals.roles.IS_ADMIN || this.globals.roles.IS_SUPER_ADMIN) {
        role = ADMIN_
      } else if (this.globals.roles.IS_SUPERVISOR) {
        role = SUPERVISOR_
      } else if (this.globals.roles.IS_COORDINATOR) {
        role = COORDINATOR_
      } else {
        role = COMMERCIAL_
      }

      return this.getUrlPrefixToShow(role, "members")
    }
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Titulaire</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-list-group flush class="list-group-fill-success">
        <BListGroupItem class="list-group-item-action"><i class="bx bx-user align-middle me-2"></i><router-link :to=" getDetailPrefixUrl + account?.user.uniqId">{{account?.user.lastname + " " + account?.user.firstname }}</router-link></BListGroupItem>
        <BListGroupItem class="list-group-item-action"><i class="bx bx-phone align-middle me-2"></i> {{account?.user.phoneNumber }}</BListGroupItem>
        <BListGroupItem class="list-group-item-action"><i class="bx bx-calendar align-middle me-2"></i>  {{account.createdAt ? account.createdAt.split('T')[0] : null}}</BListGroupItem>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>