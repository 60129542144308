<script>
import Modal from "@/components/modal.vue";
import API from "@/plugins/axios-method";
import { helpers, required, } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {DAILY, MONTHLY, QUARTERLY, WEEKLY} from "../../../helpers/constant/saving-frequency";
import {
  DEPOSITCONTRIBUTIONTRANSACTION,
} from "../../../helpers/constant/modal-type";

export default {
  name: "deposit-contribution-program-transaction-form-modal" ,
  components: {Modal},
  props: {
    title: {
      type: String,
      default: "Effectuer une épargne"
    },
    value: {},
    account: {
      type: Object,
    },
  },
  setup(){
    return { v$: useVuelidate() }
  },
  data(){
    return {
      visible: false,
      accountId: null,
      accountPhoneNumber: null,
      startDate: null,
      selectedFrequency: null,
      savingFrequency: [
        {
          value: DAILY,
          text: 'Quotidienne'
        },
        {
          value: WEEKLY,
          text: 'Hebdomadaire'
        },
        {
          value: MONTHLY,
          text: 'Menusuelle'
        },
        {
          value: QUARTERLY,
          text: 'Trimensuelle'
        }
      ],

      isSubmitting: false,
      isSubmitted: false,
    }
  },
  watch: {
    value(newValue) {
      this.visible = newValue
    },
    visible(newValue) {
      this.visible = newValue
    },
    account(newValue) {
      if (newValue) {
        this.accountId = newValue['@id']
        this.accountPhoneNumber = newValue.user.phoneNumber
      }
    },
  },
  methods: {
    onModalClose(){
      this.clearForm()
      this.visible = false
      this.isSubmitted = false
      this.isSubmitting = false
      this.$emit('onModalClose', DEPOSITCONTRIBUTIONTRANSACTION)
    },
    formSubmit(){
      if (!this.selectedFrequency) return
      const confirmation = confirm('Voulez-vous vraiment effectuer cette opération ?')
      if (!confirmation) return
      this.createProduct()
    },
    createProduct(){
      if (this.validateForm()) return
      this.isSubmitted = true

      API.post('/contribution_program_transactions', {
        account: this.accountId,
        contributionFrequency: this.selectedFrequency,
        phoneNumber: this.accountPhoneNumber
      })
          .then(response => {
            if (response.status === 201){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })

    },
    createSuccess(p){
      this.isSubmitting = false
      this.isSubmitted = false
      this.clearForm()
      this.onModalClose()
      this.openPaymentPage(p)
    },
    clearForm(){
      this.v$.$reset()
      this.selectedFrequency = null
    },
    validateForm(){
      this.v$.$validate()
      return !!this.v$.$error
    },
    openPaymentPage(p){
      window.open(p.paymentLink, '_self')
    }
  },
  validations: {
    selectedFrequency: {
      required: helpers.withMessage("La fréquence de l'épargne est requise", required),
    }
  }

}
</script>

<template>
  <Modal :title="title" @closeModal="onModalClose" v-model="visible">
    <div class="">
      <form @submit.prevent="formSubmit">
        <div class="mb-3">
          <label for="password" class="form-label">Frequence</label>
          <b-form-select :data-choice="true" :data-choices-sorting-false="true"  :class="{'is-invalid': v$.selectedFrequency.$errors.length }" v-model.trim="selectedFrequency" :options="savingFrequency"/>
          <div class="invalid-feedback" v-for="error in v$.selectedFrequency.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mt-4">
          <button :disabled="isSubmitted" class="btn btn-success w-100" type="submit">
            <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-if="!isSubmitted">Effectuer</span>
          </button>
        </div>
      </form>
    </div>
  </Modal>

</template>

<style scoped>

</style>