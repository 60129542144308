<script>
export default {
  name: "account-basique-informations",
  props: {
    account: {
      type: Object,
      required: true,
    }
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Information de base</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-list-group flush class="list-group-fill-success">
        <b-list-group-item title="Nom du compte" class="list-group-item-action"><i class="bx bx-error-alt align-middle me-2"></i> {{account?.name}}</b-list-group-item>
        <BListGroupItem title="Epargnes" class="list-group-item-action"><i class="bx bx-money align-middle me-2"></i> {{account.savings?.toLocaleString()}} XAF</BListGroupItem>
        <b-list-group-item title="Retard d'épargne" class="list-group-item-action"><i class="ri-alert-line align-middle me-2"></i> {{account.debtAmount ? account.debtAmount.toLocaleString() : 0}} XAF</b-list-group-item>
        <BListGroupItem title="Créé le" class="list-group-item-action"><i class="bx bx-calendar align-middle me-2"></i>  {{account.createdAt ? account.createdAt.split('T')[0] : null}}</BListGroupItem>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>