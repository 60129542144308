<script>
import TransactionTableCard from "@/components/dashboard/transaction/transaction-table-card.vue";
import ClientPagination from "@/components/client-pagination.vue";

export default {
  name: "account-transactions",
  components: {ClientPagination, TransactionTableCard},
  props: {
    transactions: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      currentPage: 1,
      itemsPerPage: 5,
    }
  },
  methods: {
    nextPage(){
      if (this.currentPage < (this.transactions.length / this.itemsPerPage)) {
        this.currentPage++;
      }
    },
    previousPage(){
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.transactions.slice(start, end);
    },
  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Transactions</b-card-title>
    </b-card-header>
    <b-card-body>
      <transaction-table-card
          isAllTransactions
          :page="currentPage"
          :transactions="paginatedData"
      />
      <client-pagination
          :current-page="currentPage"
          :total-pages="transactions.length / this.itemsPerPage"
          @nextPage="nextPage"
          @previousPage="previousPage"
      />
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>