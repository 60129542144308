<script>
import om_icon from "@/assets/images/operators/om.png"
import momo_icon from "@/assets/images/operators/momo.png"
import API from "../../../plugins/axios-method";
export default {
  name: "transaction-confirmation-form-modal",
  props: {
    value: {},
    onlinePaymentReq: {
      type: Object,
      default: null
    }
  },
  data(){
    return {
      visible: false,
      amount: null,
      reason: null,
      momo: "CM_MOMO",
      om: "CM_OM",
      om_icon: om_icon,
      momo_icon: momo_icon,
      selectedOperator: "CM_OM",
      isSubmitted: false,
      isSubmitting: false,
    }
  },
  watch:{
    visible(newValue) {
      this.visible = newValue
    },
    value(newValue){
      this.visible = newValue
    },
    onlinePaymentReq(newVal){
      if (newVal){
        this.paymentRequestId = newVal.paymentRquestId
        this.amount = Math.ceil(newVal.amount)
      }
    }
  },
  methods: {
    onModalClose(){
      this.visible = false
    },
    cancel(){
      this.visible = false
    },
    submit(){
      const confirmation = confirm("Voulez-vous vraiment valider cette transaction?")
      if (!confirmation) return
      API.post('/admin/payments/manual', {
        orderId: this.paymentRequestId,
        operator: this.selectedOperator,
        amount: this.amount,
      })
          .then(() => {
            window.location.reload()
          })
    }
  }
}
</script>

<template>
  <b-modal v-model="visible" @hide="onModalClose" size="lg" title="Confirmation de la transaction" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
    <b-card>
      <b-card-body>
        <b-row>
          <b-col cols="12">
<!--            <p class="me-2">{{ 659745125 }}</p>-->
          </b-col>
          <b-col cols="12">
            <p class="me-2">Vous allez effectuer une regularisation de <span class="h2">{{amount}} XAF</span></p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <label>
              <input type="radio" :value="om" v-model="selectedOperator" />  <img :src="om_icon" alt="orange money logo" height="30">
            </label>
            <label>
              <input type="radio" :value="momo" v-model="selectedOperator" />  <img :src="momo_icon" alt="mobile money logo" height="30">
            </label>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col cols="12">
            <b-button :disabled="isSubmitted" @click="submit" variant="success" class="me-4">Confirmer</b-button>
            <b-button :disabled="isSubmitted" @click="cancel" variant="danger">Annuler</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </b-modal>
</template>

<style scoped>

</style>